/* eslint-disable dot-notation */
import {
  HorizontalCard,
  HorizontalCardDescription,
  HorizontalCardSubtitle,
  HorizontalCardText,
  HorizontalCardTitle,
} from '@noissue-ui-kit/card/HorizontalCard'
import {
  VerticalCard,
  VerticalCardDescription,
  VerticalCardSubtitle,
  VerticalCardText,
  VerticalCardTitle,
} from '@noissue-ui-kit/card/VerticalCard'
import { richTextToStyledReactComponents } from '@utils/contentful/richTextParser'
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types'
import { useDevice } from '@utils/responsiveness/useDevice'
import { THorizontalPosition, TVerticalPosition } from '@utils/types'
import { IContentfulConfigurableCard } from 'types/contentful/configurableCard.types'
import { ConfigurableCta } from './ConfigurableCta'
import { useInView } from 'react-intersection-observer'
import ConfigurableSlider from './ConfigurableSlider'
import { CSSProperties } from 'react'
import { CircleCard, CircleCardTitle } from '@noissue-ui-kit/card/CircleCard'
import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { trackConfigurableCardImpression } from '@utils/gtm'
import router from 'next/router'
import { SquareCard } from '@noissue-ui-kit/card/SquareCard'
import { ComponentPlaceholder } from './ComponentPlaceholder'
import classnames from 'classnames'
import { mapTextColorToClass } from '@components/ContentfulPage/ConfigurableText/ConfigurableText.utils'
import { ConfigurableText } from './ConfigurableText/ConfigurableText'
import isEmpty from 'lodash/isEmpty'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'

interface IConfigurableCardProps extends IContentfulConfigurableCard {
  className?: string
  onCtaAction?: ({ ctaId }) => void
  style?: CSSProperties
  priorityLoadImage?: boolean
  generateStatically?: boolean
  splitView?: boolean
}

const styledComponentFromRichText = (richText: Document) => {
  return richTextToStyledReactComponents(richText, {
    [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="underline"
        href={node?.data?.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul className="list-disc list-outside text-acai !ml-[24px]">
        {children}
      </ul>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <h4 className="text-[22px] xxs:text-[clamp(22px,calc(1rem+((1vw-4.81px)*4.171)),26px)] leading-[1.2] mt-[24px]">
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <h5 className="text-[16px] xxs:text-[clamp(16px,calc(1rem+((1vw-4.81px)*4.171)),24px)] leading-[1.2] mt-[24px]">
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <h6 className="text-[16px] xxs:text-[clamp(16px,calc(1rem+((1vw-4.81px)*4.171)),20px)] leading-[1.2] mt-[24px]">
        {children}
      </h6>
    ),
  })
}

export function SingleConfigurableCard({
  cardType,
  cardBackgroundColor,
  cardSize,
  cardLink,
  image,
  cardTopBadge,
  imageBackgroundColor,
  tile,
  imageOrTilePosition,
  textBlockAlign,
  textBlockVerticalAlign,
  subtitle,
  subtitleColor,
  title,
  titleSize = 'XL',
  mobileTitleSize,
  titleColor,
  titleConfig,
  description,
  descriptionColor,
  cta,
  ctaAlign,
  text,
  textColor,
  mobileCardType,
  mobileImage,
  mobileImageOrTilePosition,
  mobileTextBlockAlign,
  mobileTextBlockVerticalAlign,
  onCtaAction,
  trackingIdentifier,
  priorityLoadImage,
  cardFooterLinks,
  cardFooterLinksAlign,
  hideCtaOnMobile,
  splitView = false,
  className = '',
}: IConfigurableCardProps) {
  const { isMobile, isTablet } = useDevice({
    mobileMediaQuery: 600,
    tabletMediaQuery: 768,
  })

  const onCtaClick = () => {
    if (onCtaAction) {
      onCtaAction({ ctaId: cta?.ctaEventId })
    }
  }

  const expectedCardType = isMobile ? mobileCardType || cardType : cardType

  const expectedTextBlockAlign = isMobile
    ? mobileTextBlockAlign || textBlockAlign
    : textBlockAlign

  const expectedTextBlockVerticalAlign = isMobile
    ? mobileTextBlockVerticalAlign || textBlockVerticalAlign
    : textBlockVerticalAlign

  const expectedImage = isMobile
    ? mobileImage?.imageUrl
      ? mobileImage
      : image
    : image

  const expectedImageOrTilePosition = isMobile
    ? mobileImageOrTilePosition || imageOrTilePosition
    : imageOrTilePosition

  const tileType = tile?.componentKey
  const tileRatio = tileType ? 50 : 0

  let horizontalTile = false
  if (tileType) {
    horizontalTile = ['left', 'right'].includes(String(imageOrTilePosition))
  }

  return (
    <>
      {expectedCardType === 'horizontal' && (
        <HorizontalCard
          cardSize={cardSize}
          backgroundColor={cardBackgroundColor}
          textBlockVerticalAlign={expectedTextBlockVerticalAlign}
          textBlockAlign={expectedTextBlockAlign}
          imagePosition={expectedImageOrTilePosition as THorizontalPosition}
          style={{
            width:
              tileRatio > 0 && horizontalTile ? `${100 - tileRatio}%` : '100%',
          }}
          image={expectedImage}
          priorityLoadImage={priorityLoadImage}
          cardFooterLinks={cardFooterLinks}
          cardFooterLinksAlign={cardFooterLinksAlign}
          cardLink={cardLink}
          cardCta={cta}
          className={`${className} @container`}
        >
          {cardTopBadge?.imageUrl && (
            <ContentfulNextJsImage
              src={cardTopBadge?.imageUrl}
              alt={cardTopBadge?.imageTitle}
              height={40}
              width={200}
              objectFit="contain"
              quality={75}
              format="avif"
            />
          )}

          {subtitle && (
            <HorizontalCardSubtitle
              className={
                mapTextColorToClass[subtitleColor] || mapTextColorToClass.acai
              }
            >
              {subtitle}
            </HorizontalCardSubtitle>
          )}

          {title && (
            <>
              {/*
               * Use new title config
               * And fallback to the old way if titleConfig is missing
               */}
              {!isEmpty(titleConfig) && (
                <ConfigurableText
                  {...titleConfig}
                  className="@7xl:max-w-[600px]"
                >
                  {title}
                </ConfigurableText>
              )}

              {isEmpty(titleConfig) && (
                <HorizontalCardTitle
                  titleSize={titleSize}
                  mobileTitleSize={mobileTitleSize}
                  className={`${
                    mapTextColorToClass[titleColor] || mapTextColorToClass.acai
                  } w-full @8xl/text-content:w-[50%]`}
                >
                  {title}
                </HorizontalCardTitle>
              )}
            </>
          )}

          {description && (
            <HorizontalCardDescription
              className={`${
                mapTextColorToClass[descriptionColor] ||
                mapTextColorToClass.acai
              } @7xl:max-w-[600px]`}
            >
              {styledComponentFromRichText(description)}
            </HorizontalCardDescription>
          )}

          {cta?.ctaLabel && (
            <ConfigurableCta
              {...{ ...cta, ctaBtnSize: isTablet ? 's' : cta.ctaBtnSize }}
              className={classnames({
                'hidden lg:flex': hideCtaOnMobile,
                'mr-auto': !ctaAlign || ctaAlign === 'left',
                'ml-auto': ctaAlign === 'right',
                'mx-auto': ctaAlign === 'center',
              })}
              onClick={onCtaClick}
            />
          )}

          {text && (
            <HorizontalCardText
              className={
                mapTextColorToClass[textColor] || mapTextColorToClass.acai
              }
            >
              {styledComponentFromRichText(text)}
            </HorizontalCardText>
          )}
        </HorizontalCard>
      )}

      {expectedCardType === 'vertical' && (
        <VerticalCard
          backgroundColor={cardBackgroundColor}
          textBlockVerticalAlign={expectedTextBlockVerticalAlign}
          textBlockAlign={expectedTextBlockAlign}
          image={expectedImage}
          imagePosition={expectedImageOrTilePosition as TVerticalPosition}
          style={{
            width:
              tileRatio > 0 && horizontalTile ? `${100 - tileRatio}%` : '100%',
          }}
          priorityLoadImage={priorityLoadImage}
          className={classnames({
            'h-1/2': !!splitView,
            'h-full': !splitView,
          })}
          cardLink={cardLink}
        >
          {cardTopBadge?.imageUrl && (
            <ContentfulNextJsImage
              src={cardTopBadge?.imageUrl}
              alt={cardTopBadge?.imageTitle}
              height={20}
              width={100}
              objectFit="contain"
              quality={75}
              format="avif"
            />
          )}

          {subtitle && (
            <VerticalCardSubtitle
              className={
                mapTextColorToClass[subtitleColor] || mapTextColorToClass.acai
              }
            >
              {subtitle}
            </VerticalCardSubtitle>
          )}

          {title && (
            <>
              {/*
               * Use new title config
               * And fallback to the old way if titleConfig is missing
               */}
              {!isEmpty(titleConfig) && (
                <ConfigurableText {...titleConfig}>{title}</ConfigurableText>
              )}

              {isEmpty(titleConfig) && (
                <VerticalCardTitle
                  titleSize={titleSize}
                  mobileTitleSize={mobileTitleSize}
                  className={
                    mapTextColorToClass[titleColor] || mapTextColorToClass.acai
                  }
                >
                  {title}
                </VerticalCardTitle>
              )}
            </>
          )}

          {description && (
            <VerticalCardDescription
              className={`${
                mapTextColorToClass[descriptionColor] ||
                mapTextColorToClass.acai
              } pb-[24px]`}
            >
              {styledComponentFromRichText(description)}
            </VerticalCardDescription>
          )}

          {cta?.ctaLabel && (
            // add CTA positioning logic here
            <ConfigurableCta
              {...cta}
              className={classnames('w-full', {
                'hidden lg:block': hideCtaOnMobile,
              })}
              onClick={onCtaClick}
            />
          )}

          {text && (
            <VerticalCardText
              className={
                mapTextColorToClass[textColor] || mapTextColorToClass.acai
              }
            >
              {styledComponentFromRichText(text)}
            </VerticalCardText>
          )}
        </VerticalCard>
      )}
      {expectedCardType === 'circle' && (
        <CircleCard
          cardSize={cardSize}
          cardLink={cardLink}
          backgroundColor={cardBackgroundColor}
          image={expectedImage}
          imageBackgroundColor={imageBackgroundColor}
          trackingIdentifier={trackingIdentifier}
        >
          {title && (
            <CircleCardTitle
              className={
                mapTextColorToClass[titleColor] || mapTextColorToClass.acai
              }
            >
              {title}
            </CircleCardTitle>
          )}
        </CircleCard>
      )}

      {expectedCardType === 'square' && (
        <SquareCard
          cardLink={cardLink}
          backgroundColor={cardBackgroundColor}
          image={expectedImage}
          imageBackgroundColor={imageBackgroundColor}
          trackingIdentifier={trackingIdentifier}
          title={title}
          titleColor={titleColor}
        />
      )}

      {tileType === 'configurableSlider' && (
        <ConfigurableSlider
          {...(tile || {})}
          slides={tile?.slides}
          style={{
            width: horizontalTile ? `${tileRatio}%` : '100%',
          }}
          generateStatically={true}
        ></ConfigurableSlider>
      )}

      {tileType === 'configurableCard' && (
        <SingleConfigurableCard
          style={{
            width: horizontalTile ? `${tileRatio}%` : '100%',
          }}
          {...(tile || {})}
          image={tile?.image}
          generateStatically={true}
        />
      )}
    </>
  )
}

function ConfigurableCardWrapper({
  tile,
  imageOrTilePosition,
  className,
  cardBackgroundColor,
  style,
  generateStatically,
  children,

  cardLink,
  trackingIdentifier,
  title,
  layoutType = 'full width',
}: Partial<IConfigurableCardProps> & { children: any }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  })

  const wrapperStyle = {}
  const wrapperFlexDirection = {
    left: 'row-reverse',
    right: 'row',
    top: 'column-reverse',
    bottom: 'column',
  }

  const tileType = tile?.componentKey

  if (tileType) {
    wrapperStyle['padding-top'] = '32px'
    wrapperStyle['padding-bottom'] = '32px'

    if (tileType !== 'configurableSlider') {
      wrapperStyle['padding-left'] = '32px'
      wrapperStyle['padding-right'] = '32px'
    }

    wrapperStyle['display'] = 'flex'
    wrapperStyle['flexDirection'] =
      wrapperFlexDirection[String(imageOrTilePosition)] ||
      wrapperFlexDirection.right
  }

  return (
    <div
      className={`font-mori tracking-mori overflow-hidden xs:pt-0 ${
        className ?? className
      } ${
        layoutType === 'with margin' &&
        'rounded-[40px] mx-6 lg:mx-44 my-12 lg:my-32'
      }`}
      style={{
        backgroundColor: cardBackgroundColor,
        ...wrapperStyle,
        ...(style || {}),
      }}
      ref={ref}
    >
      {generateStatically || inView ? (
        <EverestVisibilityDetector
          onVisible={() => {
            trackConfigurableCardImpression({
              linkUrl: cardLink,
              componentIdentifier: trackingIdentifier,
              cardTitle: title,
              pageUrl: router.asPath,
            })
          }}
          once={true}
        >
          {children}
        </EverestVisibilityDetector>
      ) : (
        <ComponentPlaceholder height={400} />
      )}
    </div>
  )
}

function SplitConfigurableCard(props: IConfigurableCardProps) {
  if (props.pairedCard) {
    const pairedCardProps = props.pairedCard

    return (
      <ConfigurableCardWrapper
        {...props}
        className={`${props.className} flex-col md:flex-row relative keen-slider__slide`}
      >
        <>
          <SingleConfigurableCard {...props} splitView={true} className="" />

          <div className="flex w-full min-h-[4px] sm:min-h-full sm:w-[4px] bg-white" />

          <SingleConfigurableCard
            {...pairedCardProps}
            splitView={true}
            priorityLoadImage={props.priorityLoadImage}
            className=""
          />
        </>
      </ConfigurableCardWrapper>
    )
  }

  return (
    <ConfigurableCardWrapper {...props}>
      <SingleConfigurableCard {...props} />
    </ConfigurableCardWrapper>
  )
}

export default SplitConfigurableCard
